<template>
  <div>
    <spinner-overlay v-model="overlay" :persistent="true">
    </spinner-overlay>
    <!--sign in form -->
    <v-card :variant="'flat'" class="auth-form" v-show="showForgotPasswordScreen && !showSignUp"> 
      <v-card-title>Password Reset</v-card-title>
      <v-card-text>
        <template v-if="!showMorePasswordRecoveryFields">  
          <v-form ref="passwordRecoveryFormOne">
            <v-text-field
                  v-model="recoveryEmail"
                  label="Enter email"
                  class="mb-2"
                  :rules="recoveryEmailRules"
                  required
                  @focus="passwordRecoveryErrors = []"
                  filled
                  outlined
                  density="compact"
                  validate-on-blur
            ></v-text-field>
            <v-list
              v-if="passwordRecoveryErrors.length"
            >
              <v-list-item>
                {{passwordRecoveryErrors[passwordRecoveryErrors.length - 1]}}
              </v-list-item>
            </v-list>
            <v-btn
                color="accent"
                depressed
                block
                @click="forgotPassword()"
                >
                Get Recovery Code
            </v-btn>
          </v-form>
        </template>
        <template v-if="showMorePasswordRecoveryFields">
          <v-form ref="passwordRecoveryFormTwo">
            <v-alert v-if="showMorePasswordRecoveryFields" style="padding-top:0px;">A verification code was sent to {{ passwordRecoveryData.Destination }}</v-alert>
            <v-text-field
              style="margin-top:8px;"
              v-model="passwordRecoveryVerificationCode"
              label="Enter Verification Code"
              :rules="verificationCodeRules"
              class="mb-2"
              required
              filled
              outlined
              density="compact"
              validate-on-blur
            ></v-text-field>    

            <v-text-field
              v-model="newPassword"
              :rules="passwordRules"
              label="New Password"
              class="signup-password"
              type="password"
              required
              validate-on-blur
              filled
              outlined
              density="compact"
              hint="At least 8 characters"
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="confirmNewPassword"
              :rules="passwordRules.concat(confirmNewPasswordRules)"
              label="Confirm New Password"
              class="mb-2"
              type="password"
              required
              validate-on-blur
              filled
              outlined
              density="compact"
            ></v-text-field>

            <v-list
              v-if="passwordRecoveryErrors.length"
            >
              <v-list-item>
                {{passwordRecoveryErrors[0]}}
              </v-list-item>
            </v-list>

            <v-btn
              class="mb-1"
              color="accent"
              depressed
              block
              @click="trySetNewPassword()"
              >
              Reset Password
            </v-btn>
          </v-form>
        </template>
      </v-card-text>
      <section class="footer">
        <p style="text-align:right; padding-right:20px;"><a class="action-text" @click="forgotPasswordGoBack()">Back</a></p>
      </section>
    </v-card>
    <v-card :variant="'flat'" class="auth-form" v-show="!showForgotPasswordScreen && !showSignUp">
      <v-alert type="success" closable v-if="showSignUpSuccessMessage">Sign up success!</v-alert>
      <v-alert type="success" closable v-if="showPasswordResetSuccessMessage">Password reset!</v-alert>
      <v-card-title>Sign in</v-card-title>
      <div @click="signInWithGoogle()" class="google-sign-in-btn"></div>
      <span class="seperator--container"><span class="seperator--or">&nbsp;&nbsp;or&nbsp;&nbsp;</span></span>
      <v-card-text>
        <v-form @keyup.enter="validateAndSubmitSignin()" v-model="valid" ref="signinForm">
          <v-text-field
            v-model="username"
            label="Username / Email"
            :rules="usernameRules"
            class="mb-2"
            @focus="signInErrors = []"
            required
            filled
            outlined
            density="compact"
            validate-on-blur
          ></v-text-field>

          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Password"
            type="password"
            validate-on-blur
            @focus="signInErrors = []"
            class="mb-2"
            required
            filled
            outlined
            density="compact"
          ></v-text-field>

          <v-list
            v-if="signInErrors.length"
          >
            <v-list-item
              v-for="(error,index) in signInErrors"
              :key="index"
            >
              {{error}}
            </v-list-item>
          </v-list>

          <v-btn
           class="mb-1"
           color="accent"
           depressed
           block
           @click="validateAndSubmitSignin()"
          >
          Sign In
          </v-btn>
          <p @click="showForgotPasswordScreen = true"><a style="font-size:0.8rem;" class="action-text">Forgot your password?</a></p>
          <section class="footer">
            <p>Don't have an account?<a @click="showSignUpScreen()" class="action-text">&nbsp;Sign Up</a></p>
          </section>
        </v-form>
      </v-card-text>
    </v-card>
    <!--sign up form-->
    <v-card :variant="'flat'" class="auth-form" v-show="showSignUp">
      <div v-if="!showVerificationScreen">
        <v-card-title>Sign up</v-card-title>
        <!-- <v-icon color="accent" class="icon--close" @click="close()">mdi-close</v-icon> -->
        <v-card-text>
          <v-form @keyup.enter="validateAndSubmitSignup()" ref="signupForm">
            <v-text-field
              v-model="signupUsername"
              :rules="usernameRules"
              label="Username"
              class="mb-2"
              required
              filled
              outlined
              density="compact"
              validate-on-blur
            ></v-text-field>

            <v-text-field
              v-model="signupEmail"
              :rules="emailRules"
              label="E-mail"
              class="mb-2"
              required
              filled
              outlined
              density="compact"
              validate-on-blur
            ></v-text-field>

            <v-text-field
              v-model="signupPassword"
              :rules="passwordRules"
              label="Password"
              class="signup-password"
              type="password"
              required
              validate-on-blur
              filled
              outlined
              density="compact"
              hint="At least 8 characters"
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="confirmPassword"
              :rules="passwordRules.concat(confirmPasswordRules)"
              label="Confirm Password"
              type="password"
              class="mb-2"
              required
              validate-on-blur
              filled
              outlined
              density="compact"
            ></v-text-field>
            <v-list
              v-if="signUpErrors.length"
            >
              <v-list-item
                v-for="(error,index) in signUpErrors"
                :key="index"
              >
                {{error}}
              </v-list-item>
            </v-list>
            <v-btn
             class="mb-1"
             @click="validateAndSubmitSignup()"
             color="accent"
             block
             depressed 
            >
            Sign Up
            </v-btn>
            <section class="footer">
              <p>Already have an account?<a class="action-text" @click="showSignInScreen()">&nbsp;Sign In</a></p>
            </section>
          </v-form>
        </v-card-text>
      </div>
      <div v-if="showVerificationScreen">
        <v-form ref="verifySignUp">
          <v-card-title>Not done yet...!</v-card-title>
          <v-card-text class="sign-up-success-message">
            A verification code was sent to {{ signupEmail }} 
            <v-text-field
              style="margin-top:8px;"
              v-model="verificationCode"
              label="Enter Verification Code"
              :rules="verificationCodeRules"
              class="mb-2"
              required
              filled
              outlined
              density="compact"
              validate-on-blur
            ></v-text-field>  
            <v-list
              v-if="signupConfirmationErrors.length"
            >
              <v-list-item
                v-for="(error,index) in signupConfirmationErrors"
                :key="index"
                :density="'compact'"
                :color="'error'"
              >
                {{error}}
              </v-list-item>
            </v-list>    
            Didn't get a code?<a @click="e => tryResendSignUp(e)">&nbsp;Click to resend</a>
            <v-btn
            class="mb-1"
            color="accent"
            depressed
            block
            @click="confirmSignUp()"
            >
              Verify
            </v-btn>
          </v-card-text>
        </v-form>
      </div>
    </v-card>
  </div>
</template>

<script>
  import NODE_API from '../utils/api';
  import { Auth } from 'aws-amplify';
  import NavBar from '../components/NavBar.vue';
  import SpinnerOverlay from '../components/SpinnerOverlay.vue';
  import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
  import { nextTick } from 'vue'


  export default {
    name: 'Login',
    components: {
      NavBar, SpinnerOverlay
    },
    props: {
      loginFormIsShowing: Boolean, 
      simple: Boolean
    },
    mounted() {  
      // google.accounts.id.initialize({
      //   client_id: "391860046963-d7m4ajreb15t9bj2dakrfh5gm4as798s.apps.googleusercontent.com",
      //   callback: this.handleCredentialResponse
      // });
      // google.accounts.id.renderButton(
      //   document.getElementById("g-btn-div"),
      //   { theme: "outline", width:"318"}  // customization attributes
      // );
      // google.accounts.id.renderButton(
      //   document.getElementById("g-btn-div2"),
      //   { theme: "outline", text: "signup_with"}  // customization attributes
      // );    
      window.addEventListener('keyup', this.submitForm); 
    },
    methods: {
      showSignInScreen() {
        this.showSignUp = false; 
        // this.resetSignInFormValues(); 
        this.showSignUpSuccessMessage = false; 
        this.showPasswordResetSuccessMessage = false; 
      },
      showSignUpScreen() {
        this.resetSignUpFormValues(); 
        this.showVerificationScreen = false; 
        this.showSignUp = true; 
      }, 
      resetSignUpFormValues() {
        this.signupUsername='';
        this.signupEmail='';
        this.signupPassword='';
        this.confirmPassword='';
      },
      resetSignInFormValues() {
        this.username = ''; 
        this.password = ''; 
      },
      resetPasswordRecoveryFormValues() {
        this.recoveryEmail = ''; 
        this.passwordRecoveryVerificationCode = ''; 
        this.newPassword = ''; 
        this.confirmNewPassword = ''; 
      }, 
      async signInWithGoogle() {
        try {
          let res = await Auth.federatedSignIn({  provider: CognitoHostedUIIdentityProvider.Google }); 
        } catch (e) {
          console.error(e); 
        }    
      },
      async confirmSignUp() {
        this.signupConfirmationErrors = []; 
        let isValid = await this.$refs.verifySignUp.validate(); 
        if (!isValid.valid) {
          return; 
        }
        try {
          this.overlay = true;
          let res = await Auth.confirmSignUp(this.signupUsername,this.verificationCode); 
          this.showSignUp = false; 
          this.resetSignUpFormValues(); 
          this.showSignUpSuccessMessage = true; 
        } catch (e) {
          this.signupConfirmationErrors.push(e.message); 
        }   
        this.overlay = false;     
      },
      submitForm(e) {
        if (!this.loginFormIsShowing || this.showSignUpSuccessMessage) {
          return; 
        }
        if(e.keyCode === 13) {
          if (this.showSignUp) {
            this.validateAndSubmitSignup(); 
          }
          if (!this.showSignUp) {
            this.validateAndSubmitSignin(); 
          }
        }
      },
      async forgotPassword() {
        let isValid = await this.$refs.passwordRecoveryFormOne.validate(); 
        if (!isValid.valid) {
          return; 
        } 
        try {
            const data = await Auth.forgotPassword(this.recoveryEmail);
            this.passwordRecoveryData = data.CodeDeliveryDetails; 
            console.log(data);
            this.showMorePasswordRecoveryFields = true; 
            this.passwordRecoveryErrors = []; 
            nextTick(() => {
              this.$refs.passwordRecoveryFormTwo.resetValidation();
            });   
        } catch(err) {
            console.log(err);
            this.passwordRecoveryErrors.push(err.message); 
        }
      }, 
      async trySetNewPassword() {
        let isValid = await this.$refs.passwordRecoveryFormTwo.validate(); 
        if (!isValid.valid) {
          return; 
        }
        this.overlay = true; 
        this.passwordRecoveryErrors = []; 
        try {
          const data = await Auth.forgotPasswordSubmit(this.recoveryEmail, this.passwordRecoveryVerificationCode, this.newPassword);
          console.log(data);
          this.passwordRecoveryErrors = []; 
          this.showForgotPasswordScreen = false; 
          this.resetPasswordRecoveryFormValues(); 
          this.showPasswordResetSuccessMessage = true; 
        } catch(err) {
          this.passwordRecoveryErrors.push(err.message); 
        }
        this.overlay = false; 
      },
      forgotPasswordGoBack() {
        if(this.showMorePasswordRecoveryFields) {
          this.showMorePasswordRecoveryFields = false; 
        } else {
          this.showMorePasswordRecoveryFields = false; 
          this.showForgotPasswordScreen = false; 
        }
        this.passwordRecoveryErrors = []; 
      },
      handleCredentialResponse(response) {
        console.log("Encoded JWT ID token: " + response.credential);
      },
      async validateAndSubmitSignin() {
  
        this.signInErrors = []; 
        let isValid = await this.$refs.signinForm.validate();
        if (isValid.valid) { 
          this.overlay = true;
       
          try {
            let metaData = {}; 
            let email = ( /.+@.+/.test(this.username)) ? this.username : ''; 
            if (email) {
              metaData.email = email; 
            }
            let res = await Auth.signIn(this.username, this.password, metaData); 
           
            if (res.username) {
              if (this.simple) {
                //this is a dialog we should close with an emit 
                this.$emit('signInSuccess'); 
              } else {
                this.$router.push('/map'); 
              }
            }
          } catch (e) { 
            if (e.name== "UserNotConfirmedException") {
              //if they tried to log in with username then cognito will return the email not verified
              //exception. 
              let res = await this.resendSignUp(this.username); 
              if (!res.success) {
                this.signInErrors.push(res.errMsg); 
              } else {
                //show verification screen 
                this.verificationCode = ''; 
                this.showVerificationScreen = true; 
                this.showSignUp = true; 
                this.signupEmail = res.details.CodeDeliveryDetails.Destination; 
                this.signupUsername = this.username; 
              }
            } else if (e.message.indexOf("UserNotConfirmedException") > -1) {
              //if they tried to log in with email alias and email was not confirmed. we did some wrangling to 
              //keep track of this scenario and threw our own error in the preAuth handler. Deal
              //with it here. 
              let username = e.message.split('-')[1]; 
              username = username.slice(0,-1).trim(); 
              this.signupUsername = username; 
          
              let res = await this.resendSignUp(this.signupUsername); 
              if (!res.success) {
                this.signInErrors.push(res.errMsg);
              } else {
                this.verificationCode = ''; 
                this.showVerificationScreen = true; 
                this.showSignUp = true; 
                this.signupEmail = this.username; //this is actually the email alias from sign in 
              }
            } else if (e.name=="PasswordResetRequiredException") {
              this.showForgotPasswordScreen = true;  
            } else {
              this.signInErrors.push(e.message); 
            }
          }
    
          this.overlay = false; 
        }
      },
      async tryResendSignUp(e) {
        this.overlay = true;
        e.preventDefault(); 
        try {
          let result = await this.resendSignUp(this.signupUsername); 
          if (!result.success) {
            throw new Error(result.errMsg); 
          } 
        } catch (err) {
          this.signupConfirmationErrors.push(err.message); 
        }
        this.overlay = false; 
      }, 
      async resendSignUp(username) {
        try {
          let details = await Auth.resendSignUp(username); 
          return { success: true, details} ; 
        } catch (e) {
          return { success: false, errMsg: e.message} 
        }
      },
      async validateAndSubmitSignup() {
        this.signUpErrors = []; 
        let isValid = await this.$refs.signupForm.validate();
        if (isValid.valid) {
          this.overlay = true;

          let username = this.signupUsername; 
          let email = this.signupEmail; 
          let password = this.signupPassword; 
          try {
            let result = await Auth.signUp({
              username, password, attributes: { email, preferred_username: username }
            }); 
            if (result.user) {
              this.verificationCode = ''; 
              this.showVerificationScreen = true; 
            }
          } catch (e) { 
            let genericMsg = 'PreSignUp failed with error'; 
            if (e.message.indexOf(genericMsg) > -1) {
              let msg = e.message.slice(genericMsg.length); 
              this.signUpErrors.push(msg); 
            } else {
              this.signUpErrors.push(e.message); 
            }
          }
          this.overlay = false; 
        }
      },
      close() {
        this.$emit('close');
      }
    },
    computed: {
      confirmPasswordRules() {
        return () => (this.signupPassword === this.confirmPassword) || 'Password must match'
      },
      confirmNewPasswordRules() {
        return () => (this.newPassword === this.confirmNewPassword) || 'Password must match'
      }
    },
    data: () => ({
      valid: false,
      password: '',
      username: '',
      signupUsername: '',
      signupEmail: '',
      signupPassword: '',
      confirmPassword: '',
      usernameRules: [
        v => !!v || "Username is required"
      ],
      recoveryEmailRules: [
        v => !!v || "Email is required",
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      passwordRules: [
        v => !!v || "Password is required", 
        v => v.trim().length >= 8 || 'Password must be at least 8 characters long'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      verificationCodeRules: [
        v => !!v || 'Need verification code',
      ],
      showSignUp: false,
      signUpErrors: [],
      signupConfirmationErrors: [], 
      showSignUpSuccessMessage: false,
      showPasswordResetSuccessMessage: false, 
      showVerificationScreen: false, 
      signInErrors: [],
      overlay: false,
      verificationCode:'',
      showForgotPasswordScreen: false, 
      recoveryEmail: '',
      showMorePasswordRecoveryFields: false,
      passwordRecoveryVerificationCode: '', 
      newPassword: '', 
      confirmNewPassword: '',
      passwordRecoveryData: {},
      passwordRecoveryErrors: []
    }),
  }
</script>

<style lang="scss" scoped>
.v-card {
  margin:50px auto 10px auto; 
  max-width: 400px;
}
  .v-list {
    padding:0px;
    margin-top:-7px;
  }
  .v-list-item {
    min-height:0px!important;
    padding:0px!important;
    font-size:12px;
    line-height:15px;
    color: rgb(var(--v-theme-error)); 
  }
  .v-card .icon--close {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor:pointer;
  }
  :deep(.v-input__details) {
    min-height:0px; 
    padding:3px; 
    .v-messages {
      min-height:0px; 
    }
  }

  .v-card p {
    margin-bottom:0px;
    // color: var(--v-primary-base);
    a {
     text-decoration:none;
     font-size:16px;
     font-weight:500;
    }
  }

  .sign-up-success-message a {
    text-decoration:none;
    font-size:14px;
    color: var(--v-accent-base);
    font-weight:500;
  }

  .v-card .footer p {
    text-align:center;
    margin-top:10px;
    margin-bottom:4px;
    color: rgba(0,0,0,.87);
  }
  ::v-deep .v-text-field__details {
    display:none;
  }
  .signup-password.v-text-field.v-text-field--enclosed ::v-deep .v-text-field__details {
    display:block; 
    padding:0px; 
    padding-top:2px; 
  }

  ::v-deep .v-input.error--text {
    margin-bottom:0px!important;
    .v-text-field__details{
      margin-top:1px;
      display:block;
      padding:0px;
    }
  }

  ::v-deep div.v-input__slot {
    margin-bottom:0px!important;
  }

  .v-sheet.v-card.auth-form {
    width: 350px;
    box-shadow: none;
    left: 50%;
    transform: translate(-50%,0);
    margin-top:20px; 
  }
  .v-btn {
    margin-top: 25px;
    color:white!important;
  }
  .logo {
    background-image: url('../assets/logo.png');
    width:200px; 
    height:45px; 
    background-size:cover; 
    position:relative;
    z-index:34;
    left:-20px; 
    top: -1px; 
  }

  .google-sign-in-btn {
    width:100%; 
    height:50px; 
    background-size: contain; 
    background-image: url('../assets/social/btn_google_signin_light_normal_web@2x.png'); 
    background-position:center; 
    cursor:pointer; 
  }

  .seperator--or {
    position: absolute;
    background-color: white;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .seperator--container {
    display: block;
    margin-top: 35px;
    position: relative;
    border-bottom: 1px solid black;
    margin-bottom: 20px;
  }
  .v-card__title {
    padding-bottom:30px; 
  }

  .btn-back {
    margin-left: 10px;
  }

  .action-text {
    color: rgb(var(--v-theme-accent)); 
    cursor:pointer; 
  }
</style>
